import {Log} from '@ethersproject/abstract-provider'
import getEthereum from '../getEthereum'
import { ethers } from 'ethers'
import { STAKING_CONTRACT } from '../../configs/contracts'
import BNEthers from '../bnEthers'

export default function unstakeOrClaim(params: {
  stakeIndex: number,
  method?: 'unstake'|'claimRewards'
}): Promise<Log> {
  const {stakeIndex, method} = params
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) {
      reject({code: 202312080319, msg: 'wallet not installed'})
      return
    }
    const signer = new ethers.providers.Web3Provider(ethereum).getSigner()
    const contractABI = [
      'function unstake(uint _index) public',
      'function claimRewards(uint _index) public'
    ]
    const stakeContract = new ethers.Contract(STAKING_CONTRACT, contractABI, signer)
    let execer = stakeContract.unstake
    if (method==='claimRewards') {
      execer = stakeContract.claimRewards
    }
    execer(BNEthers.from(stakeIndex)).then((tx: any)=>{
      tx.wait().then((tx: Log)=>{
        resolve(tx)
      }).catch((err:any)=>{
        reject(err)
      })
    }).catch((err:any)=>{
      reject(err)
    })
  })
}