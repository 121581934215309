import { Box, Paper, Typography } from "@mui/material";
import EvmWallet from "./EvmWallet";
import StakeInfo from "./StakeInfo";
import IconMedalUri from '@/assets/imgs/medal.png'
import IconCoinUri from '@/assets/imgs/coin.png'
import { observer } from "mobx-react-lite";
import { useStore } from "@/stores/hooks";
import { bigNumberCeil } from "@/utils/bigNumberCeilFloor";
import aprDay2apy from "@/utils/aprDay2apy";
import StakeButton from "./StakeButton";

export default observer(function Menu() {
  const apiDataStore = useStore('apiDataStore')
  let toolTipTitle = ''
  for (const key in apiDataStore.stepAprs) {
    const stepApr = apiDataStore.stepAprs[key]
    if (Number(key)===0) {
      toolTipTitle += `For address stake order between 1 and ${stepApr.orderPoint.toFixed(0)}, the APY is ${(100*aprDay2apy(stepApr.aprDay||0)).toFixed(2)}%; `
      continue
    }
    if (Number(key)===apiDataStore.stepAprs.length-1) {
      toolTipTitle += `For address stake order larger then or equals to ${stepApr.orderPoint.toFixed(0)}, the APY is ${(100*aprDay2apy(stepApr.aprDay||0)).toFixed(2)}%`
      continue
    }
    toolTipTitle += `For address stake order between ${apiDataStore.stepAprs[Number(key)-1].orderPoint.plus(1).toFixed(0)} and ${stepApr.orderPoint.toFixed(0)}, the APY is ${(100*aprDay2apy(stepApr.aprDay||0)).toFixed(2)}%; `
  }
  return (
<Box sx={{width: 360}}>
  <Paper elevation={2} sx={{height: '100vh', paddingX: '30px', paddingTop: '80px', paddingBottom: '20px', overflow: 'auto'}}>
    <Typography sx={{color: '#2EB316', fontSize: '26px', fontWeight: 600, marginBottom: '30px'}}>Wallet Address</Typography>
    <EvmWallet />
    <Typography sx={{color: '#2EB316', fontSize: '26px', fontWeight: 600, marginTop: '70px'}}>Current Staking Info</Typography>
    <Box sx={{marginTop: '50px'}}>
      <StakeInfo iconUri={IconMedalUri} title="Stake Period:" 
        content={apiDataStore.stakingPeriod?`${bigNumberCeil((apiDataStore.stakingPeriod)/3600/24, 4).toString()} days`:undefined} 
      />
    </Box>
    <Box sx={{marginTop: '70px'}}>
      <StakeInfo iconUri={IconCoinUri} title="APY:"
        // toolTipTitle={toolTipTitle}
        content={apiDataStore.aprDay?`${(aprDay2apy(apiDataStore.aprDay)*100).toFixed(2)}%`:undefined}
      />
    </Box>
    <Box sx={{marginTop: '90px'}}>
      <StakeButton />
    </Box>
  </Paper>
</Box>
  )
})