const NETWORKS = {
  MAIN: 'MAIN',
  TEST: 'TEST'
}

let NETWORK = 'TEST'
if (window.location.hostname.endsWith('.joltify.io')) {
  NETWORK = 'MAIN'
}

export {NETWORKS, NETWORK}