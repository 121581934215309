import { Box, Paper, Typography } from "@mui/material";
import iconUriDollar from '@/assets/imgs/icon-dollar.png'
import { observer } from "mobx-react-lite";
import { useStore } from "@/stores/hooks";
import MyStakeTableRow from "./MyStakeTableRow";
import { useEffect, useState } from "react";
import bn from "@/utils/bn";
import { bigNumberFloor } from "@/utils/bigNumberCeilFloor";
import { TOKEN_INFO } from "@/configs/contracts";
import iconUriDiamond from '@/assets/imgs/icon-diamond.png'
import iconUriClock from '@/assets/imgs/icon-clock.png'
import iconUriClockPause from '@/assets/imgs/icon-clock-pause.png'
import iconUriReward from '@/assets/imgs/icon-rewards.png'
import iconUriActions from '@/assets/imgs/icon-actions.png'
import iconUriCoin from '@/assets/imgs/coin.png'

export default observer(function Main() {
  const styleTh = {fontSize: '22px', padding: '0 8px'}

  const apiDataStore = useStore('apiDataStore')
  const evmWalletStore = useStore('evmWalletStore')
  const [totalReward, setTotalReward] = useState(bn(0))

  useEffect(()=>{
    if (evmWalletStore.address) {
      let _totalReward = bn(0)
      for (const stake of apiDataStore.stakes[evmWalletStore.address]||[]) {
        _totalReward = _totalReward.plus(stake.reward||0)
      }
      setTotalReward(_totalReward)
    } else {
      setTotalReward(bn(0))
    }
  }, [JSON.stringify(apiDataStore.stakes||{}), evmWalletStore.address])
  
  return (
<Box sx={{flexGrow: 1, padding: '16px', height: '100vh'}}>
  <Paper elevation={2} sx={{height: '100%', overflow: 'auto', padding: '76px 60px'}}>
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Typography sx={{fontSize: '30px', fontWeight: 600, marginRight: '100px'}}>My Stake</Typography>
      <img src={iconUriDollar} alt="" height='22px' />
      <Typography sx={{fontSize: '23px', fontWeight: 600, marginLeft: '8px'}}>
        Total Rewards: {bigNumberFloor(totalReward.div(bn(10).pow(TOKEN_INFO.decimals)), 6).toFormat()} JOLT
      </Typography>
    </Box>
    
    <table width='100%' style={{borderCollapse: 'collapse', marginTop: '88px', overflowX: 'auto'}}>
      <tbody>
        <tr>
          <th align="left" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center'}}>
              <img src={iconUriDiamond} height='22px' alt="" style={{marginRight: '8px'}} />
              Stake(JOLT)
            </Box>
          </th>
          <th align="left" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center'}}>
              <img src={iconUriCoin} height='22px' alt="" style={{marginRight: '8px'}} />
              APR
            </Box>
          </th>
          <th align="center" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <img src={iconUriClock} height='22px' alt="" style={{marginRight: '8px'}} />
              Period
            </Box>
          </th>
          {/* <th align="left" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center'}}>
              <img src={iconUriClockPause} height='22px' alt="" style={{marginRight: '8px'}} />
              Ends in
            </Box>
          </th> */}
          <th align="right" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
              <img src={iconUriReward} height='22px' alt="" style={{marginRight: '8px'}} />
              Rewards(JOLT)
            </Box>
          </th>
          <th align="right" style={styleTh}>
            <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'end'}}>
              <img src={iconUriActions} height='22px' alt="" style={{marginRight: '8px'}} />
              Unstake
            </Box>
          </th>
        </tr>
        {evmWalletStore.address&&apiDataStore.stakes[evmWalletStore.address]?.slice().sort((a, b) => {
          return a.endTime - b.endTime;
        }).map((stake)=>{
          return <MyStakeTableRow stakeIndex={stake.index} />
        })}
      </tbody>
    </table>
  </Paper>
</Box>
  )
})