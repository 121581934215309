import React from "react";
import { EvmWalletStore } from "./EvmWalletStore";
import { DialogStore } from "./DialogStore";
import { ApiDataStore } from "./ApiDataStore";

export const stores = Object.freeze({
  evmWalletStore: new EvmWalletStore(),
  dialogStore: new DialogStore(),
  apiDataStore: new ApiDataStore()
})

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;