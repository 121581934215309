import { Box, Dialog } from "@mui/material";
import Menu from "./components/Menu";
import Main from "./components/Main";
import CustomDialog from "./components/CustomDialog";
import { useStore } from "@/stores/hooks";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import getAndStoreParams from "@/utils/chain-utils/get-and-store-params";
import getAndStoreStakes from "@/utils/chain-utils/get-and-store-stakes";
import getAndStoreBalance from "@/utils/chain-utils/get-and-store-balances";

export default observer(function NewUI() {
  const dialogStore = useStore('dialogStore')
  const apiDataStore = useStore('apiDataStore')
  const evmWalletStore = useStore('evmWalletStore')

  useEffect(()=>{
    getAndStoreParams({apiDataStore})
  }, [apiDataStore])

  useEffect(()=>{
    getAndStoreParams({apiDataStore, address: evmWalletStore.address||undefined, onlyUpdateApy: true})
    if (!evmWalletStore.address || !apiDataStore) return
    getAndStoreStakes({apiDataStore, address: evmWalletStore.address})
    getAndStoreBalance({userAddress: evmWalletStore.address, apiDataStore})
  }, [apiDataStore, evmWalletStore.address])

  return (
<Box sx={{display: 'flex'}}>
  <Menu />
  <Main />

  {dialogStore?.dialogParams&&<Dialog open
    sx={{
      '.MuiPaper-root': {
        background: '#25333F'
      } 
    }}
  >
    <CustomDialog 
      title={dialogStore.dialogParams.title} 
      state={dialogStore.dialogParams.state} 
      message={dialogStore.dialogParams.content}
      url={dialogStore.dialogParams.url}
    />
  </Dialog>}
</Box>
  )
})