import { useEffect, useState } from "react"

export default function Countdown(props: {
  endTimeSecond: number,
  onStop?: ()=>void,
  largestUnitHour?: boolean
}) {
  const {endTimeSecond, onStop, largestUnitHour} = props
  const [day, setDay] = useState(0)
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)

  useEffect(()=>{
    const intervalId = setInterval(()=>{
      const deltTimeSecond = parseInt((endTimeSecond-new Date().getTime()/1000).toString())
      if (deltTimeSecond<0) {
        clearInterval(intervalId)
        onStop?.()
        return
      }
      const _day = parseInt((deltTimeSecond/24/3600).toString()) // parseInt only get int part of a number
      setDay(_day)
      let _hour = parseInt(((deltTimeSecond-_day*24*3600)/3600).toString())
      let _minute = parseInt(((deltTimeSecond-_day*24*3600-_hour*3600)/60).toString())
      if (largestUnitHour) {
        _hour = parseInt(((deltTimeSecond)/3600).toString())
        _minute = parseInt(((deltTimeSecond-_hour*3600)/60).toString())
      }
      setHour(_hour)
      setMinute(_minute)
      setSecond( parseInt((deltTimeSecond%60).toString()) )
    }, 100)
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  if (largestUnitHour) {
    return <> {hour} <b>H</b> {minute} <b>M</b> {second} <b>S</b> </>
  }
  return <> {day} <b>D</b> {hour} <b>H</b> {minute} <b>M</b> {second} <b>S</b> </>
}