import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function StakeInfo(parops: {
  iconUri: string,
  title: string,
  content?: string,
  toolTipTitle?: string
}) {
  const {iconUri, title, content, toolTipTitle} = parops
  return (
<Box sx={{display: 'flex'}}>
  <img src={iconUri} alt="" height='20px' />
  <Box sx={{paddingLeft: '10px'}}>
    <Typography sx={{fontSize: '22px', lineHeight: '22px', fontWeight: 500}}>{title}</Typography>
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {content?<Typography sx={{fontSize: '22px', lineHeight: '44px'}}>{content}</Typography>:<Skeleton animation="wave" width='60px' height="44px" />}
      {toolTipTitle&&<Tooltip title={toolTipTitle} arrow>
        <HelpOutlineIcon sx={{fontSize:"22px", color: 'gray', marginLeft: '8px'}} />
      </Tooltip>}
    </Box>
  </Box>
</Box>
  )
}