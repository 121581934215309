import BigNumber from "bignumber.js";
import { action, makeObservable, observable } from "mobx";

export type Stake = {
  index: number, // index on contract, used to unstake
  amount: BigNumber,
  startTime: number,
  aprDay: BigNumber,
  stakingPeriod: number,
  endTime: number,
  reward?: BigNumber
}

export type Balances = Record<
  string, // `${chainId}-${tokenAddress}-${userAddress}`, ntive token: NATIVE_TOKEN
  {amount: BigNumber, decimals: number}
>

export type StepApr = {
  orderPoint: BigNumber,
  aprDay: BigNumber
}

export class ApiDataStore {
  constructor() {
    makeObservable(this)
  }

  @observable
  stakes: Record<string, Stake[]> = {}
  @action
  setUserStakes(params: {address:string, userStakes: Stake[]}) {
    this.stakes[params.address] = params.userStakes
  }

  @observable
  stakingPeriod: number|null = null
  @action
  setStakingPeriod(stakingPeriod: number) {
    this.stakingPeriod = stakingPeriod
  }

  @observable
  aprDay: string|null = null
  @action
  setAprDay(aprDay: string) {
    this.aprDay = aprDay
  }

  @observable
  minStakeAmount: string|null = null
  @action
  setMinStakeAmount(minStakeAmount: string) {
    this.minStakeAmount = minStakeAmount
  }

  @observable
  enableStaking = false
  @action
  setEnableStaking(enableStaking: boolean) {
    this.enableStaking = enableStaking
  }

  @observable
  balances: Balances = {}
  @action
  addBalance(balances: Balances) {
    this.balances = {...this.balances, ...balances}
  }

  @observable
  stepAprs: StepApr[] = []
  @action
  setStepAprs(stepAprs: StepApr[]) {
    this.stepAprs = stepAprs
  }

  @observable
  timestampOnChain: number|null = null
  @action
  setTimestampOnChain(timestampOnChain: number) {
    this.timestampOnChain = timestampOnChain
  }

  @observable
  allowances: Record<string, BigNumber> = {}
  @action
  setAllowance(addrerss: string, allowance: BigNumber) {
    this.allowances = {
      ...this.allowances,
      [addrerss]: allowance,
    }
  }
}