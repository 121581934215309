import { Box, Button, Tooltip, Typography } from "@mui/material";
import Countdown from "@/components/Countdown/Countdown";
import { observer } from "mobx-react-lite";
import { useStore } from "@/stores/hooks";
import { Stake } from "@/stores/ApiDataStore";
import bn from "@/utils/bn";
import { TOKEN_INFO } from "@/configs/contracts";
import { bigNumberCeil, bigNumberFloor } from "@/utils/bigNumberCeilFloor";
import bscChainInfo from "@/configs/chain-info";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress'
import getAndStoreStakes from "@/utils/chain-utils/get-and-store-stakes";
import getAndStoreBalance from "@/utils/chain-utils/get-and-store-balances";
import aprDay2apy from "@/utils/aprDay2apy";
import unstakeOrClaim from "@/utils/chain-utils/unstakeOrClaim";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import calculateRewards from "@/utils/chain-utils/calculate-rewards";

export default observer(function MyStakeTableRow(props: {
  stakeIndex: number
}) {
  const {stakeIndex} = props
  const apiDataStore = useStore('apiDataStore')
  const evmWalletStore = useStore('evmWalletStore')
  const dialogStore = useStore('dialogStore')

  let stake:Stake|undefined = undefined
  if (evmWalletStore.address) {
    stake = apiDataStore.stakes[evmWalletStore.address].find(item=>{return item.index===stakeIndex})
  }

  const [btnLoading, setBtnLoading] = useState<'unstake'|'claimRewards'>()
  const [allowUnstake, setAllowUnstake] = useState((stake?.endTime||0) < new Date().getTime()/1000)
  const [reward, setReward] = useState( stake?.reward || bn(0))

  let claimEndTime = (stake?.startTime||0) + 24*3600
  if (claimEndTime > (stake?.endTime||Infinity)) {
    claimEndTime = stake?.endTime || 0
  }

  useEffect(()=>{
    const startTime = stake?.startTime
    if (!startTime || allowUnstake) {
      return
    }
    
    const dayPassed = Math.ceil( (new Date().getTime()/1000 - startTime)/24/3600 )
    let timePassed = dayPassed * 24*3600
    if (timePassed + startTime > (stake?.endTime||Infinity)) {
      return
    }
    let intervalId: NodeJS.Timer

    (async ()=>{
      try {
        const res = await calculateRewards({
          amount: stake?.amount || bn(0),
          aprDay: stake?.aprDay || bn(0),
          timePassed
        })
        const futureReward = res.minus( stake?.reward || 0 )
        intervalId = setInterval(()=>{
          const todayTimePassed = (Math.ceil(new Date().getTime()/1000) - startTime)%(24*3600)
          const todayReward = futureReward.times(todayTimePassed).div(24*3600)
          setReward(todayReward.plus(stake?.reward||0))
        }, 100)
      } catch(err) {
        console.error('calculateRewards error', err)
      }
    })()

    return () => {
      if (intervalId!==undefined) {
        clearInterval(intervalId)
      }
    }
  }, [
    stake?.startTime,
    allowUnstake
  ])
  
  function handleUnstakeOrClaim(method: 'unstake'|'claimRewards') {
    setBtnLoading(method)
    unstakeOrClaim({stakeIndex, method}).then(tx=>{
      if (evmWalletStore.address) {
        getAndStoreStakes({apiDataStore, address: evmWalletStore.address})
        getAndStoreBalance({userAddress: evmWalletStore.address, apiDataStore})
      }
      dialogStore.showDilog({
        title: 'Success',
        state: 'success',
        url: `${bscChainInfo.blockExplorerUrls[0]}/tx/${tx?.transactionHash}`
      })
    }).catch(err=>{
      dialogStore.showDilog({
        title: 'Failed',
        state: 'failed',
        content: err.mgs || err.message ||err.toString()
      })
    }).finally(()=>{
      setBtnLoading(undefined)
    })
  }

  const tdStyle = {borderBottom: '1px solid #464D5C', padding: '40px 8px 10px 8px', fontSize: '18px', lineHeight: '18px'}
  return (
<tr>
  <td align="left" style={tdStyle}>
    {bigNumberFloor(bn(stake?.amount||0).div(bn(10).pow(TOKEN_INFO.decimals)), 6).toFormat()}
  </td>
  <td style={tdStyle}>{(100*aprDay2apy(stake?.aprDay||0)).toFixed(2)}%</td>
  <td align="center" style={tdStyle}>{bigNumberCeil(bn(stake?.stakingPeriod||0).div(24*3600), 4).toFormat()} days</td>
  {/* <td style={tdStyle}>
      <Countdown 
        endTimeSecond={(stake?.endTime||0)}
        onStop={()=>setAllowUnstake(true)}
      />
  </td> */}
  <td style={tdStyle}>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
      {bigNumberFloor(bn(reward||0).div(bn(10).pow(TOKEN_INFO.decimals)), 8).toFormat(8)}
      {(stake?.reward?.isZero() && !allowUnstake) && false &&
        <Tooltip arrow sx={{marginLeft: '8px'}}
          title="Reward renews by day"
        >
          <HelpOutlineIcon />
        </Tooltip>  
      }
      {!allowUnstake&&<Button size="small" 
        disabled={stake?.reward?.isZero() || btnLoading==='claimRewards'}
        sx={{marginLeft: '8px', border: (stake?.reward?.isZero())?'1px solid grey':'1px solid #2EB316', color: '#2EB316', height: '40px', width: '130px'}}
        onClick={()=>handleUnstakeOrClaim('claimRewards')}
      >
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
          {btnLoading!=='claimRewards'&&<Typography sx={{lineHeight: '20px', fontSize: '18px'}}>Claim</Typography>}
          {btnLoading==='claimRewards'&&<CircularProgress size='18px' sx={{color: '#2EB316'}} />}
          {stake?.reward?.isZero()&&<Typography sx={{lineHeight: '14px', fontSize: '12px'}}>
            <Countdown 
              endTimeSecond={claimEndTime} 
              onStop={()=>{
                if (!evmWalletStore.address) return
                getAndStoreStakes({apiDataStore, address: evmWalletStore.address})
              }}
              largestUnitHour
            />
          </Typography>}
        </Box>
      </Button>}
    </Box>
  </td>
  <td align="right" style={tdStyle}>
    <Button size="small" sx={{border: allowUnstake?'1px solid #2EB316':'1px solid grey', fontSize: '18px', color: '#2EB316', width: '130px', height: '40px'}}
      onClick={()=>handleUnstakeOrClaim('unstake')} 
      disabled={btnLoading==='unstake'||!allowUnstake}
    >
      <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography sx={{lineHeight: '20px', fontSize: '18px'}}>
          {btnLoading!=='unstake'&&'Unstake'}
        </Typography>
        {btnLoading==='unstake'&&<CircularProgress size='18px' sx={{color: '#2EB316'}} />}
        {!allowUnstake&&<Typography sx={{lineHeight: '14px', fontSize: '12px'}}><Countdown 
          endTimeSecond={(stake?.endTime||0)}
          onStop={()=>setAllowUnstake(true)}
        /></Typography>}
      </Box>
    </Button>
  </td>
</tr>
  )
})