import { NETWORK, NETWORKS } from "../utils/networks"

let STAKING_CONTRACT = '0x638336b0b024D96FDBa072821Aa09eBD15373e41'

let TOKEN_INFO = {
  tokenAddress: '0x570095a24DD2D62d5300250EE51e47313252D3f9',
  decimals: 18
}

if (NETWORK===NETWORKS.MAIN) {
  STAKING_CONTRACT = 'to be deployed'

  TOKEN_INFO = {
    tokenAddress: '0x7Db21353A0c4659B6A9a0519066aa8D52639Dfa5',
    decimals: 18
  }
}

export {TOKEN_INFO, STAKING_CONTRACT}