import { Box, IconButton, Link, Typography } from "@mui/material"
import iconUriSuccess from '@/assets/imgs/icon-success.png'
import iconUriFailed from '@/assets/imgs/icon-failed.png'
import CloseIcon from '@mui/icons-material/Close';
import { observer } from "mobx-react-lite";
import { useStore } from "@/stores/hooks";

export default observer(function CustomDialog(props: {
  title: string|undefined,
  state: 'success'|'failed'|undefined,
  message?: string,
  url: string
} | {
  title: string|undefined,
  state: 'success'|'failed'|undefined,
  message: string,
  url?: string,
}) {
  const dialogStore = useStore('dialogStore')
  const {title, state, message, url} = props
  const iconUri = state==='success'?iconUriSuccess:iconUriFailed
  return (
<Box sx={{minWidth: '600px', minHeight: '476px', textAlign: 'center'}}>
  <Box sx={{textAlign: 'right', padding: '10px'}}>
    <IconButton onClick={()=>dialogStore.closeDialog()}>
      <CloseIcon sx={{color: '#ffffff', fontSize: '30px'}} />
    </IconButton>
  </Box>
  <Typography sx={{fontSize: '36px', color: '#2EB316', fontWeight: 600, marginTop: '30px'}}>
    {title}
  </Typography>
  <img src={iconUri} alt="joltify" height='96px' style={{marginTop: '70px'}} />
  <Box sx={{marginTop: '60px', fontSize: '26px'}}>
    <Typography sx={{fontSize: '26px', wordWrap: 'break-word'}}>{message?.substring(0, 1024)}</Typography>
    {url&&<Link href={url} target="_blank" sx={{color: '#ffffff'}}>Click here to view details</Link>}
  </Box>
</Box>
  )
})