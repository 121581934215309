import { action, makeObservable, observable } from "mobx";
export type DialogParams = {
  title?: string,
  content?: any, // can ve html node
  forbidClose?: boolean,
  url?:string,
  state?: 'success'|'failed'
}

export class DialogStore {
  constructor() {
    makeObservable(this)
  }
  @observable
  dialogParams: DialogParams|null = null

  @action
  showDilog(dialogParams: DialogParams) {
    this.dialogParams = dialogParams
  }

  @action
  closeDialog() {
    this.dialogParams = null
  }
}