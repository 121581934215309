import { NETWORK, NETWORKS } from "../utils/networks"

const bscChainInfoTest = {
  "chainId": "0x61",
  "chainName": "BSCTest",
  "nativeCurrency": {
    "name": "BNB",
    "symbol": "BNB",
    "decimals": 18
  },
  "rpcUrls": [
    "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s3.binance.org:8545/",
    "https://data-seed-prebsc-2-s3.binance.org:8545/"
  ],
  "blockExplorerUrls": ["https://testnet.bscscan.com"]
}

const bscChainInfoMain = {
  "chainId": "0x38",
    "chainName": "Smart Chain",
    "nativeCurrency": {
      "name": "BNB",
      "symbol": "BNB",
      "decimals": 18
    },
  "rpcUrls": [
    "https://bsc-dataseed1.binance.org/",
    "https://bsc-dataseed2.binance.org/",
    "https://bsc-dataseed3.binance.org/",
    "https://bsc-dataseed4.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed2.defibit.io/",
    "https://bsc-dataseed3.defibit.io/",
    "https://bsc-dataseed4.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc-dataseed2.ninicoin.io/",
    "https://bsc-dataseed3.ninicoin.io/",
    "https://bsc-dataseed4.ninicoin.io/"
  ],
  "blockExplorerUrls": ["https://bscscan.com"]
}

let bscChainInfo = bscChainInfoTest
if (NETWORK===NETWORKS.MAIN) {
  bscChainInfo = bscChainInfoMain
}

export default bscChainInfo