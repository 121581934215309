import { ethers, BigNumber as BNEthers } from "ethers"
import getEthereum from "../getEthereum"
import { EvmWalletStore } from "../../stores/EvmWalletStore"
import bn from "../bn"
import BigNumber from "bignumber.js"
import { TOKEN_INFO, STAKING_CONTRACT } from "../../configs/contracts"

export default function approveOrNot(params: {
  amount: BigNumber.Value,
  evmWalletStore: EvmWalletStore
}): Promise<any> {
  const {amount, evmWalletStore} = params
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) {
      reject({code: 202312080043, msg: 'wallet not installed'})
      return
    }
    if (!evmWalletStore.address) {
      reject({code: 202312080045, msg: 'Please connect to wallet'})
      return
    }
    if (bn(amount).lte(0)) {
      resolve(null)
      return
    }
    const signer = new ethers.providers.Web3Provider(ethereum).getSigner()
    const contractABI = [
      'function allowance(address owner, address spender) external view returns (uint256)',
      'function approve(address spender, uint256 amount) public returns (bool)'
    ]
    const erc20Contract = new ethers.Contract(TOKEN_INFO.tokenAddress, contractABI, signer)
    // get allowance, if allowance<amount, trigger approve
    erc20Contract.allowance(evmWalletStore.address, STAKING_CONTRACT).then((allowance:BNEthers)=>{
      if (bn(amount).times(bn(10).pow(TOKEN_INFO.decimals)).gt(allowance.toString())) {
        erc20Contract.approve(STAKING_CONTRACT, BNEthers.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')).then((tx:any)=>{
          tx.wait().then(()=>{
            resolve(null)
          }).catch((err:any)=>{
            reject(err)
          })
        }).catch((err:any)=>{
          reject(err)
        })
      } else {
        resolve(null)
      }
    }).catch((err:any)=>{
      reject(err)
    })
  })
}