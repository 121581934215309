import { Box, Typography } from "@mui/material";
import bg from '../../../assets/imgs/wallet-btn-bg.png'

export default function WalletButton(prpos: {
  children: any
  // ref?: any
}) {
  const {children,
    // ref
  } = prpos
  return (
<Box id='WalletButton'
  // ref={ref}
  sx={{
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    width: '300px', height: '60px',
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    paddingX: '24px'
  }}
>
  {children}
</Box>
  )
}