import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "@/stores/hooks";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import bn from "@/utils/bn";
import { TOKEN_INFO } from "@/configs/contracts";
import bscChainInfo from "@/configs/chain-info";
import { bigNumberCeil, bigNumberFloor } from "@/utils/bigNumberCeilFloor";
import stake from "@/utils/chain-utils/stake";
import getAndStoreStakes from "@/utils/chain-utils/get-and-store-stakes";
import getAndStoreBalance from "@/utils/chain-utils/get-and-store-balances";
import CircularProgress from '@mui/material/CircularProgress';
import getAndStoreAllowance from "@/utils/chain-utils/get-and-store-allowance";
import { NATIVE_TOKEN } from "@/configs/consts";
import approve from "@/utils/chain-utils/approve";

export default observer(function StakeButton() {
  const evmWalletStore = useStore('evmWalletStore')
  const dialogStore = useStore('dialogStore')
  const apiDataStore = useStore('apiDataStore')
  const minStakeAmount = bn(apiDataStore.minStakeAmount||0).div(bn(10).pow(TOKEN_INFO.decimals))

  const balanceKey = `${bscChainInfo.chainId}-${TOKEN_INFO.tokenAddress}-${evmWalletStore.address}`
  const balance = apiDataStore.balances[balanceKey]?.amount||bn(0)
  const allowance = apiDataStore.allowances?.[evmWalletStore.address||NATIVE_TOKEN] || bn(0)


  const [inputVal, setInputVal] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [btnToShow, setBtnToShow] = useState<'stake'|'approve'>()
  const [ifShowStakeWindow, setIfShowStakeWindow] = useState(false)

  useEffect(()=>{
    if (!ifShowStakeWindow) setInputVal('')
  }, [ifShowStakeWindow])

  useEffect(()=>{
    if (evmWalletStore.address) {
      getAndStoreAllowance({address: evmWalletStore.address, apiDataStore})
    }
  }, [apiDataStore, evmWalletStore.address])

  useEffect(()=>{
    // setBtnToShow('approve'); return // for debug
    if (!inputVal || bn(inputVal).lte(0)) {
      setBtnToShow(undefined)
      return
    }
    if (bn(inputVal).times(bn(10).pow(TOKEN_INFO.decimals)).gt(allowance)) {
      setBtnToShow('approve')
      return
    }
    setBtnToShow('stake')
  }, [inputVal, allowance])

  function handleStakeClick() {
    if (!evmWalletStore.address) {
      dialogStore.showDilog({
        title: 'Warning ⚠️',
        state: 'failed',
        content: 'Please connect to wallet'
      })
      return
    }
    setIfShowStakeWindow(true)
  }

  const handleStake = () => {
    if (!evmWalletStore.address) {
      dialogStore.showDilog({content: 'Please connect to wallet', title: 'Warning ⚠️'})
      return
    }
    if (bn(inputVal||0).lt(minStakeAmount)) {
      dialogStore.showDilog({
        content: `Minimal input amout is ${bigNumberCeil(minStakeAmount, 0).toFormat()} JOLT`, 
        title: 'Warning ⚠️'
      })
      return
    }
    if (balance.lt(inputVal||0)) {
      dialogStore.showDilog({
        content: `Input exceeds balance`, 
        title: 'Warning ⚠️'
      })
      return
    }

    setBtnLoading(true)
    stake({
      amount: inputVal,
      evmWalletStore
    }).then(tx=>{
      if (evmWalletStore.address) {
        getAndStoreStakes({apiDataStore, address: evmWalletStore.address})
        getAndStoreBalance({userAddress: evmWalletStore.address, apiDataStore})
      }
      setIfShowStakeWindow(false)
      dialogStore.showDilog({
        title: 'Stake Success',
        state: 'success',
        url: `${bscChainInfo.blockExplorerUrls[0]}/tx/${tx?.transactionHash}`
      })
      if (evmWalletStore.address) {
        getAndStoreAllowance({address: evmWalletStore.address, apiDataStore})
      }
    }).catch(err=>{
      dialogStore.showDilog({
        title: 'Stake Failed',
        content: err.mgs||err.toString()
      })
    }).finally(()=>{
      setBtnLoading(false)
    })
  }

  function handleApprove() {
    setBtnLoading(true)
    approve({evmWalletStore}).then(()=>{
      if (!evmWalletStore.address) {
        setBtnLoading(false)
        return
      }
      getAndStoreAllowance({address: evmWalletStore.address, apiDataStore}).finally(()=>{
        setBtnLoading(false)
      })
    }).catch(err=>{
      console.error('approve error', err)
      setBtnLoading(false)
    })
  }
  
  return (
<>
  <Button size="large" variant="contained" onClick={handleStakeClick}
    sx={{width: '140px', background: '#2EB316', color: '#ffffff', fontSize: '22px',
      '&:hover': {bgcolor: '#5EC269'}
    }}
  >Stake</Button>

  {ifShowStakeWindow&&<Dialog open
    sx={{
      '.MuiPaper-root': {
        background: '#25333F'
      } 
    }}
  >
    <Box sx={{minWidth: '600px', minHeight: '476px'}}>
      <Box sx={{textAlign: 'right', padding: '10px'}}>
        <IconButton onClick={()=>setIfShowStakeWindow(false)}>
          <CloseIcon sx={{color: '#ffffff', fontSize: '30px'}} />
        </IconButton>
      </Box>
      <Box sx={{paddingX: '85px', paddingBottom: '10px'}}>
        <Typography sx={{fontSize: '30px', fontWeight: 600}}>Your Amount:</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', background: '#ffffff', borderRadius: '6px'}}>
          <input autoComplete="off" value={inputVal} disabled={btnLoading}
            style={{height: '70px', flexGrow: 1, border: '0', outline: 'none',
              textAlign: 'right', fontSize: '26px', padding: '0 0 0 20px', background: 'transparent',
              // paddingRight: inputVal?undefined:'20px'
            }} 
            onChange={(e)=>{
              if (! /^\d?(\d+[\.]?\d*)?$/.test(e.target.value) ) return
              setInputVal(e.target.value)
            }}
          />
          {inputVal&&<>
            <IconButton onClick={()=>setInputVal('')} disabled={btnLoading}>
              <HighlightOffIcon sx={{color: inputVal?'grey':'transparent'}} />
            </IconButton>
          </>}
          <Button size="large" sx={{color: '#2EB316'}}
            onClick={()=>{
              if (btnLoading) return
              setInputVal(bigNumberFloor(balance, 18).toFixed())
            }}
          >Max</Button>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
          <Typography
            sx={{color: '#2EB316', fontSize: '20px', lineHeight: '20px', marginTop: '32px'}}
          >Minimal Amout: {`${bigNumberCeil(minStakeAmount, 0).toFormat()}`} JOLT</Typography>
          <Typography sx={{fontSize: '26px', marginTop: '50px', lineHeight: '26px'}}>
            Balance: { bigNumberFloor(balance, 6).toFormat()} JOLT
          </Typography>
          {btnToShow==='stake'&&<Button size="large" variant="contained" onClick={handleStake} disabled={btnLoading}
            sx={{width: '140px', background: '#2EB316', color: '#ffffff', fontSize: '22px',
              '&:hover': {bgcolor: '#5EC269'}, marginTop: '57px'
            }}
          >
            {!btnLoading&&'Stake'}
            {btnLoading&&<CircularProgress size='22px' color="inherit" />}
          </Button>}
          {btnToShow==='approve'&&<Button size="large" variant="contained" onClick={handleApprove} disabled={btnLoading}
            sx={{width: '140px', background: '#2EB316', color: '#ffffff', fontSize: '22px',
              '&:hover': {bgcolor: '#5EC269'}, marginTop: '57px'
            }}
          >
            {!btnLoading&&'Approve'}
            {btnLoading&&<CircularProgress size='22px' color="inherit" />}
          </Button>}
        </Box>
      </Box>
    </Box>
  </Dialog>}
</>
  )
})