import { ethers } from "ethers";
import { ApiDataStore, StepApr } from "../../stores/ApiDataStore";
import bscChainInfo from "../../configs/chain-info";
import { STAKING_CONTRACT } from "../../configs/contracts";
import BNEthers from "../bnEthers";
import { NATIVE_TOKEN } from "@/configs/consts";
import bn from "../bn";

export default async function getAndStoreParams(params: {
  apiDataStore: ApiDataStore,
  address?: string,
  onlyUpdateApy?: boolean
}) {
  const {apiDataStore, onlyUpdateApy} = params
  let address = params.address
  if (!address) {
    address = NATIVE_TOKEN
  }
  const provider = new ethers.providers.JsonRpcProvider(bscChainInfo.rpcUrls[0])
  const contractABI = [
    'function stakingPeriod() public view returns (uint)',
    'function getAprDay(address) public view returns (uint)',
    'function minStakeAmount() public view returns (uint)',
    'function enableStaking() public view returns (uint)',
    'function stepAprs(uint) public view returns (uint orderPoint, uint aprDay)'
  ]
  const stakeContract = new ethers.Contract(STAKING_CONTRACT, contractABI, provider)
  stakeContract.getAprDay(address).then((apr: BNEthers)=>{
    apiDataStore.setAprDay(apr.toString())
  })
  if (!onlyUpdateApy) {
    stakeContract.stakingPeriod().then((stakingPeriod: BNEthers)=>{
      apiDataStore.setStakingPeriod(stakingPeriod.toNumber())
    })
    stakeContract.minStakeAmount().then((minStakeAmount: BNEthers)=>{
      apiDataStore.setMinStakeAmount(minStakeAmount.toString())
    })
    stakeContract.enableStaking().then((enableStaking: boolean)=>{
      apiDataStore.setEnableStaking(enableStaking)
    })

    let queryNotEnd = true
    let index = 0
    let stepAprs: StepApr[] = []
    while(queryNotEnd) {
      try {
        const res = await stakeContract.stepAprs(index, {gasPrice: 0})
        // console.log(res)
        stepAprs.push({
          orderPoint: bn(res.orderPoint?.toString()||0),
          aprDay: bn(res.aprDay?.toString()||0)
        })
        index++
      } catch(err) {
        queryNotEnd = false
        // console.error('error getting stakes', err)
      }
    }
    // stepAprs.sort((a, b) => a.orderPoint.minus(b.orderPoint).toNumber())
    // console.log({stepAprs})
    apiDataStore.setStepAprs(stepAprs)
  }
}