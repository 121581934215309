import BigNumber from "bignumber.js";
import { ethers, BigNumber as BNethers } from "ethers";
import getEthereum from "../getEthereum";
import { STAKING_CONTRACT } from "@/configs/contracts";
import bn from "../bn";

export default function calculateRewards(params: {
  amount: BigNumber,
  aprDay: BigNumber,
  timePassed: number
}): Promise<BigNumber> {
  const {amount, aprDay, timePassed} = params
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(ethereum)
    const contractABI = [
      `function calculateRewards(uint amount, uint aprDay, uint timePassed) public view returns(uint reward)`
    ]
    const stakeContract = new ethers.Contract(STAKING_CONTRACT, contractABI, provider)
    stakeContract.calculateRewards( BNethers.from(amount.toFixed(0)), BNethers.from(aprDay.toFixed(0)), timePassed)
    .then((reward: BNethers)=>{
      resolve(bn(reward.toString()))
    }).catch((err:any)=>{
      console.error('calculateRewards error', err)
      reject(err)
    })
  })
}