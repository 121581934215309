import evmSwitchChain from "./evmSwitchChain";
import getEthereum from "./getEthereum";
import bscChainInfo from "../configs/chain-info";

export default function detectAndSwapEvmChain(): Promise<any> {
  return new Promise((resolve, reject)=>{
    const ethereum = getEthereum()
    if (!ethereum) {
      reject({code: 202312061645, msg: 'EVM wallet not found'})
      return
    }
    if (bscChainInfo.chainId!==ethereum.chainId) {
      evmSwitchChain(bscChainInfo.chainId).then(()=>{
        resolve(null)
      }).catch(err=>{
        if (err.code===4902) {
          ethereum.request({
            "method": "wallet_addEthereumChain",
            "params": [bscChainInfo]
          }).then(()=>{
            resolve(null)
          }).catch((err: any)=>{
            reject(err)
          })
          return
        }
        reject(err)
      })
      return
    }
    resolve(null)
  })
}