import { STAKING_CONTRACT, TOKEN_INFO } from "../../configs/contracts"
import { EvmWalletStore } from "../../stores/EvmWalletStore"
import bnParseUnits from "../bigNumberParseUnits"
import BNEthers from "../bnEthers"
import getEthereum from "../getEthereum"
import approveOrNot from "./approveOrNot"
import {ethers} from 'ethers'
import {Log} from '@ethersproject/abstract-provider'

export default function stake(params: {
  amount: string,
  evmWalletStore: EvmWalletStore
}): Promise<Log|null> {
  const {amount, evmWalletStore} = params
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) {
      reject({code: 202312080127, msg: 'wallet not installed'})
      return
    }
    approveOrNot({amount, evmWalletStore}).then(()=>{
      const signer = new ethers.providers.Web3Provider(ethereum).getSigner()
      const contractABI = [
        'function stake(uint _amount) external'
      ]
      const stakeContract = new ethers.Contract(STAKING_CONTRACT, contractABI, signer)
      stakeContract.stake(BNEthers.from(bnParseUnits(amount, TOKEN_INFO.decimals).toFixed())).then((tx: any)=>{
        console.log('stake tx1', tx)
        tx.wait().then((tx: Log)=>{
          console.log('stake tx2', tx)
          resolve(tx)
        }).catch((err:any)=>{
          reject(err)
        })
      }).catch((err:any)=>{
        reject(err)
      })
    }).catch((err:any)=>{
      reject(err)
    })
  })
}