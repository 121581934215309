import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material';
import NewUI from './pages/NewUI/NewUI';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'dark',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiPaper: {
        // variants: [
        //   {
        //     props: {elevation: 2},
        //     style: {
        //       background: 'ffff00',
        //       boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        //     }
        //   }
        // ],

        styleOverrides: {
          elevation2: {
            background: '#25333F',
          }
        }
      }
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NewUI />
    </ThemeProvider>
  ); 
}

export default App;
