import getEthereum from "../getEthereum"
import { EvmWalletStore } from "@/stores/EvmWalletStore"
import { ethers, BigNumber as BNEthers } from "ethers"
import { STAKING_CONTRACT, TOKEN_INFO } from "@/configs/contracts"

export default function approve(params: {
  spender?: string,
  amount?: string,
  evmWalletStore: EvmWalletStore
}): Promise<any> {
  let {spender, evmWalletStore, amount} = params
  if (!amount) {
    amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  }
  if (!spender) {
    spender = STAKING_CONTRACT
  }
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) {
      reject({code: 202312271050, msg: 'wallet not installed'})
      return
    }
    if (!evmWalletStore.address) {
      reject({code: 202312271051, msg: 'Please connect to wallet'})
      return
    }
    const contractABI = [
      'function approve(address spender, uint256 amount) public returns (bool)'
    ]
    const signer = new ethers.providers.Web3Provider(ethereum).getSigner()
    const erc20Contract = new ethers.Contract(TOKEN_INFO.tokenAddress, contractABI, signer)
    erc20Contract.approve(spender, BNEthers.from(amount))
    .then((tx:any)=>{
      tx.wait().then((res: any)=>{
        // console.log('approve res', res)
        resolve(res)
      }).catch((err:any)=>{
        reject(err)
      })
    }).catch((err:any)=>{
      reject(err)
    })
  })
}