import { ethers } from "ethers";
import { ApiDataStore, Stake } from "../../stores/ApiDataStore";
import getEthereum from "../getEthereum";
import { STAKING_CONTRACT } from "../../configs/contracts";
import bn from "../bn";

export default async function getAndStoreStakes(params: {
  apiDataStore: ApiDataStore,
  address: string
}) {
  const {apiDataStore, address} = params
  const ethereum = getEthereum()
  if (!ethereum) return
  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner() // signer required
  const contractABI = [
    `function stakes(address owner, uint index) public view returns (
      uint amount,
      uint startTime,
      uint aprDay,
      uint stakingPeriod,
      uint endTime
    )`,
    `function calculateRewards(uint amount, uint aprDay, uint timePassed) public view returns(uint reward)`
  ]
  const stakeContract = new ethers.Contract(STAKING_CONTRACT, contractABI, signer)
  let queryNotEnd = true
  let index = 0
  let userStakes: Stake[] = []
  let timestampOnChain = (await provider.getBlock('latest')).timestamp
  
  while(queryNotEnd) {
    try {
      const res = await stakeContract.stakes(address, index, {gasPrice: 0})
      let claimTime = timestampOnChain
      const endTime = res.startTime + res.stakingPeriod
      if (claimTime>endTime) {
        claimTime = endTime
      }
      const timePassed = claimTime - res.startTime
      const rewardRes = await stakeContract.calculateRewards(res.amount||0, res.aprDay||0, timePassed)
      // console.log(res)
      userStakes.push({
        index, // index on contract, used to unstake
        amount: bn(res.amount?.toString()||0),
        startTime: bn(res.startTime?.toString()||0).toNumber(),
        aprDay: bn(res.aprDay?.toString()||0),
        stakingPeriod: bn(res.stakingPeriod?.toString()||0).toNumber(),
        reward: bn(rewardRes?.toString()||0),
        endTime: bn(res.endTime?.toString()||0).toNumber()
      })
      index++
    } catch(err) {
      queryNotEnd = false
      // console.error('error getting stakes', err)
    }
  }
  apiDataStore.setUserStakes({address, userStakes})
}