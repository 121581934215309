import BigNumber from "bignumber.js";
import getEthereum from "../getEthereum";
import { ethers, BigNumber as BNEthers } from "ethers";
import { STAKING_CONTRACT, TOKEN_INFO } from "@/configs/contracts";
import { ApiDataStore } from "@/stores/ApiDataStore";
import bn from "../bn";

export default function getAndStoreAllowance(params: {
  address: string,
  apiDataStore: ApiDataStore
}): Promise<BigNumber> {
  const {address, apiDataStore} = params
  const ethereum = getEthereum()
  return new Promise((resolve, reject)=>{
    if (!ethereum) {
      reject({code: 202312270951, msg: 'wallet not installed'})
      return
    }
    const signer = new ethers.providers.Web3Provider(ethereum).getSigner()
    const contractABI = [
      'function allowance(address owner, address spender) external view returns (uint256)'
    ]
    const erc20Contract = new ethers.Contract(TOKEN_INFO.tokenAddress, contractABI, signer)
    erc20Contract.allowance(address, STAKING_CONTRACT).then((allowance:BNEthers)=>{
      const allowanceBn = bn(allowance.toString())
      apiDataStore.setAllowance(address, allowanceBn)
      resolve(allowanceBn)
    }).catch((err:any)=>{
      reject(err)
    })
  })
}