import { Box, Button, Dialog, Link, Popover, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "@/stores/hooks";
import detectAndSwapEvmChain from "@/utils/detectAndSwapEvmChain";
import connectEvmWallet from "@/utils/connectEvmWallet";
import CircularProgress from '@mui/material/CircularProgress'
import getEthereum from "@/utils/getEthereum";
import bscChainInfo from "@/configs/chain-info";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import evmSwitchChain from "@/utils/evmSwitchChain";
import WalletButton from "./WalletButton";

export default observer(function EvmWallet() {
  const evmWalletStore = useStore('evmWalletStore')
  const dialogStore = useStore('dialogStore')
  const [btnLoading, setBtnLoading] = useState(false)
  const [ifShowPopover, setIfShowPopover] = useState(false)
  const anchorRef = useRef(null)
  const [needToSwitchChain, setNeedToSwitchChain] = useState(false)

  useEffect(()=>{
    const ethereum = getEthereum()
    ethereum?.on('chainChanged', handleChainChanged)
    if (evmWalletStore.address) {
      ethereum?.on('accountsChanged', handleAccountsChanged)
      // handle account and chain changed
      // if chain not right, dialog show
    }
    return () => {
      const ethereum = getEthereum()
      ethereum?.removeListener('accountsChanged', handleAccountsChanged)
      ethereum?.removeListener('chainChanged', handleChainChanged)
    }
  }, [evmWalletStore.address, handleAccountsChanged, handleChainChanged])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleAccountsChanged(accounts:string[]) {
    evmWalletStore.login(accounts[0])
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleChainChanged(_chainId: string) {
    const ethereum = getEthereum()
    if (ethereum.chainId !== bscChainInfo.chainId && evmWalletStore.address) {
      setNeedToSwitchChain(true)
    } else {
      setNeedToSwitchChain(false)
    }
  }

  async function handler() {
    if (evmWalletStore.address) {
      setIfShowPopover(true)
      return
    }
    setBtnLoading(true)
    try {
      console.log('before detectAndSwapEvmChain')
      await detectAndSwapEvmChain()
      console.log('after detectAndSwapEvmChain')
    } catch(err:any) {
      console.error('error detectAndSwapEvmChain', err)
      dialogStore.showDilog({
        title: 'Failed to Connect to Wallet',
        state: 'failed',
        content: err.msg || err.message || err.toString(),
      })
      setBtnLoading(false)
      return
    }
    console.log('before connectEvmWallet')
    connectEvmWallet({evmWalletStore}).catch(err=>{
      console.error('error connectEvmWallet', err)
      dialogStore.showDilog({
        title: 'Failed to Connect to Wallet',
        state: 'failed',
        content: err.msg || err.message || err.toString()
      })
    }).finally(()=>{
      console.log('connectEvmWallet finally')
      setBtnLoading(false)
    })
  }
  return (
<>
  <Box ref={anchorRef} onClick={()=>{handler()}} sx={{cursor: 'pointer'}}>
    <WalletButton>
      <Box sx={{width: '100%', display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {!btnLoading&&<Typography sx={{fontSize: '24px', flexGrow: 1, textAlign: evmWalletStore.address?undefined:'center'}}>
          {evmWalletStore.address?`${evmWalletStore.address.slice(0, 8)}...${evmWalletStore.address.slice(-6)}`:'Connect to Metamask'}
        </Typography>}
        {btnLoading&&<Box sx={{textAlign: 'center', width: '100%'}}>
          <CircularProgress size='24px' color="inherit" />
        </Box>}
        {evmWalletStore.address&&<ExpandMoreIcon />}
      </Box>
    </WalletButton>
  </Box>

  <Popover
    open={ifShowPopover}
    anchorEl={anchorRef?.current}
    onClose={()=>setIfShowPopover(false)}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <Box 
      onClick={()=>{
        evmWalletStore.logout()
        setIfShowPopover(false)
      }}
      sx={{cursor: 'pointer'}}
    >
      <WalletButton >
        <Typography sx={{fontSize: '24px'}}>Logout</Typography>
      </WalletButton>
    </Box>
  </Popover>

  {needToSwitchChain&&<Dialog open
    sx={{
      '.MuiPaper-root': {
        backgroundColor: '#25333F'
      } 
    }}
  >
    <Box sx={{padding: '20px'}}>
      <Button size="small" color="warning"
        onClick={()=>evmSwitchChain(bscChainInfo.chainId)}
      >Click here</Button> to switch chain to {bscChainInfo.chainName}({bscChainInfo.chainId})
    </Box>
  </Dialog>}
</>
  )
})