import { ethers } from "ethers";
import { ApiDataStore } from "../../stores/ApiDataStore";
import getEthereum from "../getEthereum";
import { TOKEN_INFO } from "../../configs/contracts";
import BNEthers from "../bnEthers";
import bn from "../bn";
import bscChainInfo from "../../configs/chain-info";

export default function getAndStoreBalance(params: {
  userAddress: string,
  apiDataStore: ApiDataStore
}) {
  const {userAddress, apiDataStore} = params
  if (!userAddress) return
  const ethereum = getEthereum()
  if (!ethereum) return
  const signer = new ethers.providers.Web3Provider(ethereum).getSigner() // signer required
  const contractABI = [
    'function balanceOf(address) public view returns (uint amount)'
  ]
  const erc20Contract = new ethers.Contract(TOKEN_INFO.tokenAddress, contractABI, signer)
  erc20Contract.balanceOf(userAddress).then((amount: BNEthers)=>{
    const decimals = TOKEN_INFO.decimals
    const tokenAmout = bn(amount.toString()).div(bn(10).pow(decimals))
    const balanceKey = `${bscChainInfo.chainId}-${TOKEN_INFO.tokenAddress}-${userAddress}`
    apiDataStore.addBalance({[balanceKey]: {
      amount: tokenAmout, decimals
    }})
  })
}